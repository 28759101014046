
jQuery(document).ready(function($) {
    if($('.woocommerce-review-link').length) {
        $('.woocommerce-review-link').click(function() {
            $('html, body').animate({
                scrollTop: $('#single-product-tabs').offset().top - 200
            }, 500);
        });
    }

    if($('.woocommerce-product-details__short-description .read-more').length) {
        //console.log('read-more exists');
        $('.woocommerce-product-details__short-description .read-more').click(function(e) {
            e.preventDefault();
            //console.log('read-more clicked');
            $('#tab-title-description a').click();
            $('html, body').animate({
                scrollTop: $('#single-product-tabs').offset().top - 200
            }, 500);
        });
    }
});
