
jQuery(document).ready(function() {

    if(jQuery('.woocommerce-form-register').length > 0){
        
        // If the string "xxxxx" is present in the woocommerce-error div
        if(jQuery('.woocommerce-error').text().indexOf('email') >= 0) {
            jQuery('.woocommerce-form-register').find('input[name="email"]').addClass('input-error');
        }
        if(jQuery('.woocommerce-error').text().indexOf('username') >= 0){
            jQuery('.woocommerce-form-register').find('input[name="username"]').addClass('input-error');
        }
        if(jQuery('.woocommerce-error').text().indexOf('utilisateur') >= 0){
            jQuery('.woocommerce-form-register').find('input[name="username"]').addClass('input-error');
        }

        // On click on submit button
        jQuery('.woocommerce-form-register .submit_container span').click(function () { 
            if(jQuery('.woocommerce-password-hint').length > 0) {
                jQuery('.woocommerce-form-register').find('input[name="password"]').addClass('input-error');
            } else {
                jQuery('.woocommerce-form-register').find('input[name="password"]').removeClass('input-error');
            }
        });

        // On password input change
        jQuery('.woocommerce-form-register input[name="password"]').change(function () {
            console.log('change');
            if(jQuery('.woocommerce-password-hint').length > 0) {
                jQuery('.woocommerce-form-register').find('input[name="password"]').addClass('input-error');
            } else {
                jQuery('.woocommerce-form-register').find('input[name="password"]').removeClass('input-error');
            }
        });


    }

});