document.addEventListener('DOMContentLoaded', function() {
    // Check if the plus and minus buttons exist
    const minusButton = document.querySelector('.buy-card-container .minus');
    const plusButton = document.querySelector('.buy-card-container .plus');
    const quantityInput = document.querySelector('.buy-card-container .quantity input[type="number"]');

    if (minusButton && plusButton && quantityInput) {
        // Handle the click event on the minus button
        minusButton.addEventListener('click', function() {
            let currentValue = parseInt(quantityInput.value, 10);
            if (currentValue > 1) {
                quantityInput.value = currentValue - 1;
            }
        });

        // Handle the click event on the plus button
        plusButton.addEventListener('click', function() {
            let currentValue = parseInt(quantityInput.value, 10);
            let max = quantityInput.getAttribute('max') === '' ? Infinity : parseInt(quantityInput.getAttribute('max'), 10);
            if (currentValue < max) {
                quantityInput.value = currentValue + 1;
            }
        });
    }
});
