

jQuery(document).ready(function() {

    jQuery('.wdgk_donation').attr('placeholder', '...');

    jQuery('body').on('click', '.arrondir_note', function() {
    //jQuery('.arrondir_note').click(function (e) { 
        cart_total = jQuery('.order-total bdi').html();
        console.log(cart_total);
        cart_total = cart_total.replace(/&.*>/, '');
        cart_total = cart_total.replace(',', '.');
        cart_total = parseFloat(cart_total);
        console.log(cart_total);

        montant_arrondi = 1 - (parseFloat(cart_total) % 1);
        montant_arrondi = parseFloat(montant_arrondi).toPrecision(2);
        console.log(montant_arrondi);
        montant_arrondi_virgule = montant_arrondi.replace('.', ',');
        jQuery('.wdgk_donation').val(montant_arrondi_virgule);
        jQuery('.wdgk_add_donation')[0].click();
    });
});