
// jQuery(document).ready(function () {
//     var current_lang = document.getElementsByTagName('html')[0].getAttribute('lang');
//     current_lang = current_lang.split('-')[0];
//     console.log('Current lang : '+current_lang);

//     jQuery('.language-switcher a').each(function () {
//         jQuery(this).parent().removeClass('active');
//         var lang = jQuery(this).attr('onClick');
//         lang = lang.replace("doGTranslate('fr|", "");
//         lang = lang.replace("');return false;", "");
//         console.log('Menu item lang : '+lang);
//         if (lang == current_lang) {
//             jQuery(this).parent().addClass('active');
//             console.log('Adding active class to menu item lang : '+lang);
//         }
//     });
// });