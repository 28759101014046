
// If products exists on the page
jQuery(document).ready(function($) {
    function adjustProductHeights() {
        // Define the width threshold
        const widthThreshold = 768; // Example: 768px for tablets

        if($('ul.products').length) {
            $('ul.products').each(function() {
                // Get highest product block in the product list
                let highestElementHeight = 0;
                $(this).find('li.product').each(function() {
                    const currentElementHeight = $(this).outerHeight();
                    if(currentElementHeight > highestElementHeight) {
                        highestElementHeight = currentElementHeight;
                    }
                });

                // Apply or remove fixed height based on viewport width
                // if(window.innerWidth > widthThreshold) {
                //     // Set all elements of the list to the same height
                //     $(this).find('li.product').css('height', highestElementHeight + 'px');
                // } else {
                //     // Remove fixed height
                //     $(this).find('li.product').css('height', '');
                // }

                // Apply fixed height no matter what (there is now 2 products side by side, even on mobile)
                $(this).find('li.product').css('height', highestElementHeight + 'px');
            });
        }
    }

    // Run on document ready
    adjustProductHeights();

    // Reapply when window is resized
    $(window).resize(adjustProductHeights);
});
