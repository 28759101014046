
/*
Script by : Clem
--------------------------------------------
||             Custom Selects             ||
--------------------------------------------

Mettre la class "custom-select" sur les selects qu'on veut pouvoir customiser.
Le "faux" select de remplacement aura la class ".select-styled"

Reference: http://jsfiddle.net/BB3JK/47/
*/

jQuery(document).ready(function () {
  
	jQuery('.custom-select').each(function(){
		var $this = jQuery(this);
		var numberOfOptions = jQuery(this).children('option').length;

		$this.addClass('select-hidden'); 
		$this.wrap('<div class="select"></div>');
		$this.after('<div class="select-styled"></div>');

		var $styledSelect = $this.next('div.select-styled');
		$styledSelect.text($this.children('option').eq(0).text());

		var $list = jQuery('<ul />', {
			'class': 'select-options'
		}).insertAfter($styledSelect);

		for (var i = 0; i < numberOfOptions; i++) {
			jQuery('<li />', {
				text: $this.children('option').eq(i).text(),
				rel: $this.children('option').eq(i).val()
			}).appendTo($list);
		}

		var $listItems = $list.children('li');

		$styledSelect.click(function(e) {
			e.stopPropagation();
			jQuery('div.select-styled.active').not(this).each(function(){
				jQuery(this).removeClass('active').next('ul.select-options').hide();
			});
			jQuery(this).toggleClass('active').next('ul.select-options').toggle();
		});

		$listItems.click(function(e) {
			e.stopPropagation();
			$styledSelect.text(jQuery(this).text()).removeClass('active');
			$this.val(jQuery(this).attr('rel'));
			$list.hide();
			console.log($this.val());
			$this.val(2).change();
		});

		jQuery(document).click(function() {
			$styledSelect.removeClass('active');
			$list.hide();
		});

	});

});