/*
Script by : Clem
-----------------------------------------
||           Bootstrap nav++           ||
-----------------------------------------
*/

navbar_dropdown_on_over = true;
collapse_opening_direction = 'vertical';

jQuery(document).ready(function () {

	// Gestion des images catégories dans la nav
	jQuery('.cat_imgs_container_dummy').each(function( index ) {
		images = jQuery(this).html(); 
		jQuery(this).parent().children('.dropdown-menu').children('.cat_imgs_container').html(images);
	});

	//if(device_is_mobile) {
	if(device_is_desktop) {
		//console.log('DEVICE: Desktop');
		// Ouverture des dropdowns si option true
		if(navbar_dropdown_on_over == true) {
			// DROPDOWN NAV SUR HOVER
			jQuery('.navbar-nav > .nav-item.dropdown').mouseenter(
				function() {
					//console.log('enter');
					$this = jQuery(this);
					if (typeof mouse_left !== 'undefined') { clearTimeout(mouse_left); }
					if(!jQuery(this).hasClass('show')) {
						//console.log('hasnt "show"');
						$this.children('.dropdown-toggle').dropdown('toggle');
						$this.addClass('show');
					}
				}
			);
			jQuery('.navbar-nav > .nav-item.dropdown').mouseleave(
				function() {
					//console.log('leave');
					$this = jQuery(this);
					if(jQuery(this).hasClass('show')) {
						//console.log('has "show"');
						mouse_left = setTimeout(function(){
							//console.log('timeout done');
							$this.children('.dropdown-toggle').dropdown('toggle');
							$this.removeClass('show');
						},150);
						
					}
				}
			);

			jQuery('.nav-link.dropdown-toggle').click(function () { 
				link = jQuery(this).attr('href');
				//console.log(link);
				window.location.href = link;
			});
		}

		// Animer la navbar-collapse si on est en horizontal
		if(collapse_opening_direction == 'horizontal') {
			jQuery('.navbar-toggler').click(function() {

				if(jQuery('.navbar-collapse').hasClass('show')) {
					translate_state = 100;
					var animate_collapse = window.setInterval(function(){
						jQuery('.navbar-collapse').css('transform', 'translateX('+translate_state+'%)');
						translate_state - 2;
						if (translate_state <= 0) {
							clearInterval(animate_collapse);
						}
					}, 10);
					jQuery('.navbar-collapse').attr('style', '');
				} else {
					translate_state = 0;
					var animate_collapse = window.setInterval(function(){
						jQuery('.navbar-collapse').css('transform', 'translateX('+translate_state+'%)');
						translate_state + 2;
						if (translate_state >= 100) {
							clearInterval(animate_collapse);
						}
					}, 10);
					jQuery('.navbar-collapse').attr('style', '');
				}

			});
		}
	} else {
		//console.log('DEVICE: Mobile');
		jQuery('.nav-link.dropdown-toggle').click(function () { 
			if(!jQuery(this).parent().parent().hasClass('navbar-nav')) {
				link = jQuery(this).attr('href');
				console.log(link);
				window.location.href = link;
			}
		});
	}


	// Gestion menu langues
	menu_lang = jQuery('.gtranslate_wrapper, .open_language_switcher');
	jQuery('#menu-langues > li > a, .open_language_switcher').click(function (e) { 
		e.preventDefault();
		if(menu_lang.hasClass('show')) {
			menu_lang.removeClass('show');
		} else {
			menu_lang.addClass('show');
		}
	});

	// GESTION NAV MOBILE V2
	//if(device_is_mobile) {

		jQuery('.navbar-toggler').click(function (e) { 
			e.preventDefault();
			jQuery('.full_nav_container').addClass('opened');
			jQuery('.full_nav_container .backdrop').addClass('opened');
			jQuery('body').addClass('fixed');
		});

		jQuery('.full_nav_container .backdrop').click(function (e) { 
			e.preventDefault();
			//console.log('Backdrop clicked');
			//jQuery('.navbar-collapse').toggle();
			jQuery('.navbar-collapse').removeClass('show');
			jQuery('.navbar-toggler').addClass('collapsed');
			jQuery('.full_nav_container').removeClass('opened');
			jQuery('.full_nav_container .backdrop').removeClass('opened');
			jQuery('body').removeClass('fixed');
		});
	//}
});
