
jQuery(document).ready(function () {
    jQuery('.conditions_checkbox').click(function (e) { 
        console.log('clicked');
        //e.preventDefault();
        checked = jQuery(this).is(':checked');
    
        if(checked) {
            jQuery(this).parent().parent().find('.button').removeClass('disabled');
            jQuery(this).parent().parent().find('.button').prop("disabled", false);
        } else {
            jQuery(this).parent().parent().find('.button').addClass('disabled');
            jQuery(this).parent().parent().find('.button').prop("disabled", true);
        }
    });
});
