
jQuery(document).ready(function () {
    if(jQuery('section.featured_products .swiper').length) {

        // INITIALISATION SLIDERS
        const swiper_featured_products = new Swiper('section.featured_products .swiper', {
            // slidesPerView: 4,
            // slidesPerColumn: 2,
            spaceBetween: 30,
            //slidesPerColumnFill: 'row',
            //slidesPerColumnFill: 'column',
            // grid: {
            //     rows: 2,
            // },
            navigation: {
                nextEl: 'section.featured_products .swiper-button-next',
                prevEl: 'section.featured_products .swiper-button-prev',
            },
            pagination: {
                el: 'section.featured_products .swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                992: {
                    slidesPerView: 4,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                576: {
                    slidesPerView: 1,
                    spaceBetween: 20
                }
            }
        });

        // Get height of the heightest slide
        // swiper_height = 0;
        // jQuery('section.featured_products .swiper-slide').each(function (index, element) {
        //     var height = jQuery(element).height();
        //     if (height > swiper_height) {
        //         swiper_height = height;
        //     }
        // });
        // console.log(swiper_height);

    }

    if(jQuery('section.sales_products .swiper').length) {

        // INITIALISATION SLIDERS
        const swiper_sales_products = new Swiper('section.sales_products .swiper', {
            // slidesPerView: 4,
            // slidesPerColumn: 2,
            spaceBetween: 30,
            //slidesPerColumnFill: 'row',
            //slidesPerColumnFill: 'column',
            // grid: {
            //     rows: 2,
            // },
            navigation: {
                nextEl: 'section.sales_products .swiper-button-next',
                prevEl: 'section.sales_products .swiper-button-prev',
            },
            pagination: {
                el: 'section.sales_products .swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                992: {
                    slidesPerView: 4,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                576: {
                    slidesPerView: 1,
                    spaceBetween: 20
                }
            }
        });

    }
    
    if(jQuery('section.cheap_products .swiper').length) {

        // INITIALISATION SLIDERS
        const swiper_cheap_products = new Swiper('section.cheap_products .swiper', {
            // slidesPerView: 4,
            // slidesPerColumn: 2,
            spaceBetween: 30,
            //slidesPerColumnFill: 'row',
            //slidesPerColumnFill: 'column',
            // grid: {
            //     rows: 2,
            // },
            navigation: {
                nextEl: 'section.cheap_products .swiper-button-next',
                prevEl: 'section.cheap_products .swiper-button-prev',
            },
            pagination: {
                el: 'section.cheap_products .swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                992: {
                    slidesPerView: 4,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                576: {
                    slidesPerView: 1,
                    spaceBetween: 20
                }
            }
        });

    }
});
